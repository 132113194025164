



























































































import { Component, Vue } from 'vue-property-decorator'
import { JoinMember, UserProfile } from '@/models'
import GroupMemberService from '@/services/GroupMemberService'
import GroupService from '@/services/GroupService'
import FormatDate from '@/helpers/FormatDate'
import PreviewText from '@/helpers/PreviewText'

@Component
export default class JoinRequest extends Vue {
  private joinMember: JoinMember[] = []
  private groupLink: string = ''
  private page: number = 1
  private maxPage: number = 0
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists

  created() {
    this.getJoinMember()
  }

  getJoinMember(loadMore?: boolean) {
    GroupService.getGroupById(this.$route.params.groupId, ['group_link'])
      .then(res => {
        if (res.status === 200) {
          this.groupLink = res.data.group_link
          GroupMemberService.getJoinMembersByGroupId({
            group_link: res.data.group_link,
            page: this.page,
            limit: this.limit
          })
            .then(res => {
              if (res.status === 200) {
                if (loadMore) {
                  this.joinMember = [...this.joinMember, ...res.data.data]
                } else {
                  this.joinMember = res.data.data
                }
                this.maxPage = res.data.last_page
              }
            })
            .catch(err => {
              this.$bvModal.show('modal-error-join-request')
            })
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-join-request')
      })
  }

  loadMore() {
    this.page++
    this.getJoinMember(true)
  }

  deniedById(joinMember: JoinMember) {
    this.$blockui.show()
    GroupMemberService.deniedJoinRequest({
      id: joinMember.id,
      group_link: this.groupLink
    })
      .then(res => {
        if (res.status === 200) {
          this.getJoinMember()
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-join-request')
      })
      .finally(() => this.$blockui.hide())
  }

  approvalById(joinMember: JoinMember) {
    this.$blockui.show()
    GroupMemberService.approvalJoinRequest({
      id: joinMember.id,
      group_link: this.groupLink
    })
      .then(res => {
        if (res.status === 200) {
          this.getJoinMember()
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-join-request')
      })
      .finally(() => this.$blockui.hide())
  }

  approvalAll() {
    this.$blockui.show()
    GroupMemberService.approvalAllJoinRequest({
      group_link: this.groupLink
    })
      .then(res => {
        if (res.status === 200) {
          this.getJoinMember()
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-join-request')
      })
      .finally(() => this.$blockui.hide())
  }

  getFullName(profile?: UserProfile) {
    const name = [
      profile?.first_name,
      profile?.last_name,
      String(this.$t('groups.member_list.name_suffix'))
    ].join(' ')
    const preview = PreviewText.covertToPreviewText(name, 15)
    return {
      name: name,
      text: preview.text,
      isLong: preview.isLong
    }
  }

  formatDate(date: string) {
    return FormatDate.formatDate(date)
  }
}
